import 'scss/pages/about-us.scss';
import React from 'react';
import { Trans } from '@lingui/macro';
import Layout from 'components/Layout';
import Seo from 'components/SEO';
import Image from 'components/atoms/Image/Image';
import {Link} from 'gatsby';
import {List, ListItem} from 'components/atoms/List/List';
import {careerContactFormProps, Perks} from "../../page-components/careers/common";

const TechnicalSalesSpecialist = () => {
  return (
      <Layout contactFormProps={careerContactFormProps} >
        <Seo title="Senior Cloud Engineer at YanchWare"/>
        <section className="hero-wrapper">
          <div className="hero">
            <div className="hero__content">
              <h1 className="hero__title">
                <Trans>Senior Cloud Engineer @ YanchWare</Trans>
              </h1>
              <p className="hero__text">
                <Trans>
                  As a Senior Cloud Engineer at YanchWare, you will be instrumental in the development and optimization
                  of our cloud-based offerings, particularly through leveraging our innovative Fractal Cloud platform.
                </Trans>
              </p>
              <p className="hero__text">
                <Trans>
                  This role involves deep technical work with major public cloud vendors such as AWS, Azure, and GCP,
                  and aims to package complex cloud solutions into accessible components for our customers. Your
                  expertise will help shape the future of our cloud services and the way we deliver cloud solutions.
                </Trans>
              </p>
              <Link to="/contact-us" className="bg-green-900 w-[190px] mt-m py-xs px-m rounded-[10px] flex justify-between">
                <span className="text-transparent bg-clip-text bg-hero-text">
                  <Trans>Apply now</Trans>
                </span>
                <Image name="arrow-icon" className="fill-icon mr-s" />
              </Link>
            </div>
            <Image name="careers/senior-cloud-engineer" className="hero__image"/>
          </div>
        </section>

        <section className="container tac">
          <h2 className="color-primary">
            <Trans>About the role</Trans>
          </h2>

          <div className="content-blocks content-blocks--reverse">
            <div className="content-block">
              <Image
                  name="about-us-page/customer-success"
                  className="content-block__image"
              />
              <div className="content-block__info">
                <h2>
                  <Trans>Responsibilities:</Trans>
                </h2>
                <p>
                  <Trans>
                    In this role, you will have the opportunity to:
                  </Trans>
                </p>
                <p>
                  <List>
                    <ListItem>
                      <Trans>
                        Cloud Integration and Automation: Design and implement robust cloud solutions across multiple
                        platforms (AWS, Azure, GCP), focusing on automation and scaling of our cloud services.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Solution Packaging: Develop and refine Fractal Cloud components, making complex cloud
                        integrations more accessible and manageable for our clients.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Technical Leadership: Provide expertise and guidance on cloud architecture and best practices
                        to both internal teams and clients.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Continuous Improvement: Stay abreast of industry trends and advancements in cloud technology to
                        continuously improve our offerings.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Cross-Platform Expertise: Work with private cloud technologies and hypervisors (e.g., VMWare,
                        Cisco, Palo Alto, AVI) to ensure seamless integration and functionality across diverse
                        environments.
                      </Trans>
                    </ListItem>
                  </List>
                </p>
              </div>
            </div>
            <div className="content-block">
              <Image
                  name="about-us-page/about-us-hero"
                  className="content-block__image"
              />
              <div className="content-block__info">

                <h2>
                  <Trans>Success Criteria:</Trans>
                </h2>
                <p>
                  <Trans>
                    Your effectiveness will be measured by:
                  </Trans>
                </p>
                <p>
                  <List>
                    <ListItem>
                      <Trans>
                        Innovation and Efficiency: Successfully delivering innovative and efficient cloud solutions
                        that meet the needs of our clients.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Client Satisfaction: Enhancing client satisfaction through the reliable, secure, and
                        cost-effective performance of our cloud solutions.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Technical Leadership: Becoming a go-to expert in cloud solutions within the company, helping to
                        guide strategic decisions and mentor junior staff.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Operational Excellence: Ensuring the resilience, scalability, and security of all deployed
                        cloud solutions, with a keen focus on automation and simplification.
                      </Trans>
                    </ListItem>
                  </List>
                </p>
              </div>
            </div>
            <div className="content-block">
              <Image
                  name="careers/whoareu"
                  className="content-block__image"
              />
              <div className="content-block__info">
                <h2>
                  <Trans>Who are you:</Trans>
                </h2>
                <p>
                  <Trans>
                    You are a highly skilled and experienced cloud engineer with a passion for technology and
                    innovation:
                  </Trans>
                </p>
                <p>
                  <List>
                    <ListItem>
                      <Trans>
                        Deep Cloud Expertise: You have extensive experience with major public cloud platforms like AWS,
                        Azure, and GCP, and are proficient in deploying and managing scalable cloud architectures.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Private Cloud Knowledge: Familiarity with private cloud infrastructures and hypervisors (e.g.,
                        VMWare, Cisco) is a plus, enhancing your ability to integrate broad cloud solutions.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Problem Solver: You excel in solving complex technical issues, with a focus on improving
                        efficiency and performance.
                      </Trans>
                    </ListItem>
                    <ListItem>
                      <Trans>
                        Communicative and Collaborative: You are adept at communicating complex technical information
                        to non-technical stakeholders and thrive in a collaborative team setting.
                      </Trans>
                    </ListItem>
                  </List>
                </p>
              </div>
            </div>
          </div>
          <Perks/>
        </section>
      </Layout>
  );
};

export default TechnicalSalesSpecialist;
